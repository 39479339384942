import { ArrowForward } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import laptop_books_image from "../Constants/images/laptop-books.jpeg";

// Functional component for the Home Page
function Home() {
  // MUI theming
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // The action center must be resized for screen sizes smaller than xl
  const shiftActionCenter = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Grid container spacing={matches ? 2 : 5}>
      <Grid item sm={12} md={8} xl={6}>
        <Paper
          elevation={20}
          sx={{
            m: 5,
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <img
            src={laptop_books_image}
            width="100%"
            style={{ display: "block" }}
            alt="stack of textbooks and a laptop"
          />
        </Paper>
      </Grid>
      <Grid item sm={12} md={4} xl={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <Typography
            component="div"
            variant={matches ? "h6" : "h3"}
            sx={{ m: matches ? 1 : 2, textAlign: "center" }}
          >
            The community resource for current and future
            <i> learning engineers</i>
          </Typography>
          {!shiftActionCenter && ( // for large screen sizes, action center is next to the image
            <Paper
              variant="outlined"
              sx={{
                p: 3,
                borderColor: theme.palette.primary.dark,
                ml: 10,
                mr: 10,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Action Center</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    color="primary"
                    size="large"
                    component={Link}
                    to={"https://groups.google.com/g/learning-engineering"}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      Join the Google Group
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    size="large"
                    color="primary"
                    component={Link}
                    to={"/suggest"}
                  >
                    <Typography variant="subtitle1">
                      Contribute to this Project
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Competitions / Opportunities
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    size="large"
                    color="primary"
                    component={Link}
                    to={"/learn/degree-programs"}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      Explore Learning Engineering Degree Programs
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
      </Grid>
      {shiftActionCenter && ( // For smaller screen sizes, action center goes under the image
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{
              p: 3,
              borderColor: theme.palette.primary.dark,
              ml: matches ? 5 : 10,
              mr: matches ? 5 : 10,
              mb: 5,
            }}
          >
            <Typography variant="h6">Action Center</Typography>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              color="primary"
              size={matches ? "small" : "large"}
              component={Link}
              to={"https://groups.google.com/g/learning-engineering"}
              sx={{ m: matches ? 1 : 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ overflowWrap: "break-word" }}
              >
                Join the Google Group
              </Typography>
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              size={matches ? "small" : "large"}
              color="primary"
              component={Link}
              to={"/suggest"}
              sx={{ m: matches ? 1 : 2 }}
            >
              <Typography variant="subtitle1">
                Contribute to this Project
              </Typography>
            </Button>
            <Typography variant="h6">Competitions / Opportunities</Typography>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              size={matches ? "small" : "large"}
              color="primary"
              component={Link}
              to={"/learn/degree-programs"}
              sx={{ m: matches ? 1 : 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ overflowWrap: "break-word" }}
              >
                Explore Learning Engineering Degree Programs
              </Typography>
            </Button>
            <Button
              variant="outlined"
              endIcon={<ArrowForward />}
              size={matches ? "small" : "large"}
              color="primary"
              component={Link}
              to={"https://www.vitalprize.org/"}
              sx={{ m: matches ? 1 : 2 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ overflowWrap: "break-word" }}
              >
                Apply to the VITAL Prize Challenge
              </Typography>
            </Button>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider variant="middle" sx={{ ml: 10, mr: 10 }} />
      </Grid>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            mt: 5,
            mb: 5,
            ml: shiftActionCenter ? 10 : 40,
            mr: shiftActionCenter ? 10 : 40,
            overflowWrap: "break-word",
            borderColor: theme.palette.primary.dark,
          }}
        >
          <Typography variant={matches ? "h6" : "h2"}>
            What is Learning Engineering?
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Learning engineering drives improvements in real-world education
            outcomes by leveraging advances in computer science to accelerate
            the pace, relevance, and application of learning science.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            <b>
              <i>The Problem</i>
            </b>
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Today, research in learning science (the scientific, humanistic, and
            critical theoretical understanding of learning) suffers from slow,
            small-scale, and data-poor processes. As a result, there exists a
            gap between the newest findings on teaching and learning and
            approaches that are used with actual students.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            <b>
              <i>The Learning Engineering Solution</i>
            </b>
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Learning engineers increase the speed and effectiveness of learning
            science research through automated data collection, analysis,
            learning analytics and more. This allows for rapid experimentation
            and continuous improvement of the learning platforms, environments,
            and methodologies that they deliver to learners.
          </Typography>
          <Box
            sx={{
              mx: "auto",
              width: "fit-content",
              display: "flex",
              gap: 5,
              flexWrap: "wrap",
              mt: 2,
              justifyContent: "space-around",
            }}
          >
            <Button variant="contained" component={Link} to="/learn">
              Want to Learn More?
            </Button>
            <Button variant="contained" component={Link} to="/build">
              Want to Build Something?
            </Button>
          </Box>
        </Paper>
      </Grid>

      {/* This is not yet populated and also uses a cookie. Until we populate this with LE Events, this 
      should not be used on the site. Once it is put back onto the site, the privacy policy must be updated
      to indicate that we use cookies and a banner must be displayed on the site allowing the user to opt-in
      or out
      <Grid item lg={5} md={12} xs={12}>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            m: 5,
            height: "fit-content",
            borderColor: theme.palette.primary.dark,
          }}
        >
          {matches ? ( // use the agenda view of the Google calendar when viewed on a phone / tablet
            <iframe
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTitle=1&title=Learning%20Engineering%20Events&mode=AGENDA&src=Y19mZjFmNzExODJhMTEzYTRlZWRkYjcxYTM1Mzk4MGQzNDNiNjJlNzQxMWUyMmZkODlmZmRiMjA4NzZjYWNhNDI1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F6BF26"
              style={{ border: 0, width: "100%", height: 400 }}
              title="learning engineering events google calendar: agenda view"
            ></iframe>
          ) : (
            <iframe
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTitle=1&title=Learning%20Engineering%20Events&src=Y19mZjFmNzExODJhMTEzYTRlZWRkYjcxYTM1Mzk4MGQzNDNiNjJlNzQxMWUyMmZkODlmZmRiMjA4NzZjYWNhNDI1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F6BF26"
              style={{ border: 0, width: "100%", height: 450 }}
              title="learning engineering events google calendar: month view"
            />
          )}
        </Paper>
      </Grid> */}
    </Grid>
  );
}

export default Home;
