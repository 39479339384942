import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DegreeProgram, DegreePrograms } from "../Constants/DegreePrograms";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { createCommaSeparatedList } from "../Constants/functions";

// Today's date
const today = new Date();

// Regexp for formatting tuition values
function currencyFormat(num: number) {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

// Functional component for rendering individual degree program pages
export default function IndividualDegreeProgram() {
  // Get the program id from the url
  const { id } = useParams();
  // Get the program + its metadata using its id
  const program: DegreeProgram | undefined = DegreePrograms.find(
    (item) => item.id === id
  );

  // MUI theming
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // Pull out and reformat fields as needed
  const URL = program ? program.link : null;

  const startTerms = program && program.startTerm ? program.startTerm : ["-"];
  const renderStartTerms = createCommaSeparatedList(startTerms);

  const typesOfCourses = program && program.courses ? program.courses : [];
  const renderTypesOfCourses = typesOfCourses.map((item, index) => {
    return (
      <ListItem
        key={index}
        sx={{
          p: 0.5,
        }}
      >
        <Typography variant="body2">{item}</Typography>
      </ListItem>
    );
  });

  const typesOfTitles =
    program && program.outcomes && program.outcomes.title
      ? program.outcomes.title
      : [];
  const renderTypesOfTitles = typesOfTitles.map((item, index) => {
    return (
      <ListItem
        key={index}
        sx={{
          p: 0.5,
        }}
      >
        <Typography variant="body2">{item}</Typography>
      </ListItem>
    );
  });

  const typesOfOrgs =
    program && program.outcomes && program.outcomes.organization
      ? program.outcomes.organization
      : [];
  const renderTypesOfOrgs = typesOfOrgs.map((item, index) => {
    return (
      <ListItem
        key={index}
        sx={{
          p: 0.5,
        }}
      >
        <Typography variant="body2">{item}</Typography>
      </ListItem>
    );
  });

  const universityFundingOpportunities =
    program && program.university.funding ? program.university.funding : ["-"];
  const renderUniversityFundingOpportunities = createCommaSeparatedList(
    universityFundingOpportunities
  );

  const programFundingOpportunities =
    program && program.programFunding ? program.programFunding : ["-"];
  const renderProgramFundingOpportunities = createCommaSeparatedList(
    programFundingOpportunities
  );

  // used to return the user to previous page when they press the back button
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} md={1}>
        <Box
          sx={{
            mt: matches ? 3 : 10,
            ml: matches ? 0 : 5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={(e) => navigate(-1)}
            // Get this to save filter state
            variant="outlined"
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={10}>
        <Paper elevation={10} sx={{ m: matches ? 2 : 10 }}>
          {!program ? (
            <Box sx={{ p: 2 }}>
              <Typography>
                Hmm we can't find a program with the id {id}
              </Typography>
            </Box>
          ) : (
            <Grid container sx={{ p: 2 }}>
              <Grid
                item
                xs={12}
                sm={6}
                bgcolor={theme.palette.secondary.contrastText}
              >
                <Typography variant={matches ? "h5" : "h3"}>
                  {program.university.name}
                </Typography>
                <Typography variant="h5">
                  <b>
                    Program Name: <i>{` ${program.programName}`}</i>
                  </b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                }}
                bgcolor={theme.palette.secondary.contrastText}
              >
                <Button
                  component={Link}
                  variant="contained"
                  disabled={!URL ? true : false}
                  to={URL ? URL : ""}
                  sx={{ marginLeft: "auto" }}
                >
                  Learn More + Apply
                </Button>
                <Typography variant="body2" sx={{ marginLeft: "auto" }}>
                  <i>Information was sourced from {program.university.name}</i>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={1}
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    m: matches ? 1 : 3,
                    p: 2,
                  }}
                >
                  <Grid container spacing={5}>
                    <Grid item lg={6} md={12}>
                      <Paper
                        variant="outlined"
                        sx={{ bgcolor: theme.palette.secondary.light, p: 2 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Program at a Glance:
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2">
                              <b>Program Goal:</b>
                            </Typography>
                            <Typography variant="body2">
                              <i>{program.goal}</i>
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2">
                              <b>Program Duration: </b>
                            </Typography>
                            <Typography variant="body2">
                              <i>
                                {program.duration}{" "}
                                {program.durationMax
                                  ? `- ${program.durationMax}` +
                                    `${
                                      program.durationMax > 1
                                        ? ` years`
                                        : ` year`
                                    }`
                                  : `${
                                      program.duration > 1 ? ` years` : ` year`
                                    }`}
                              </i>
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2">
                              <b>Nearest Application Deadline:</b>
                            </Typography>
                            <Typography variant="body2">
                              <i>
                                {" "}
                                {program.deadline && program.deadline >= today
                                  ? program.deadline.toLocaleDateString(
                                      "en-us",
                                      {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }
                                    )
                                  : program.rolling
                                  ? "Rolling"
                                  : "-"}
                              </i>
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2">
                              <b>Start Term: </b>
                            </Typography>
                            <Typography variant="body2">
                              <i>{renderStartTerms}</i>
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body2">
                              <b>Virtual? </b>
                            </Typography>
                            <Typography variant="body2">
                              <i> {program.virtual ? program.virtual : "-"}</i>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        variant="outlined"
                        sx={{
                          bgcolor: theme.palette.secondary.light,
                          p: 2,
                          mt: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Program Financing
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {" "}
                            <Typography variant="body2">
                              <b>
                                {program.estimatedTuitionCost &&
                                program.estimatedTuitionCostOutOfState
                                  ? "Estimated Tuition + Fees (In-State):"
                                  : "Estimated Tuition + Fees:"}
                              </b>
                            </Typography>
                            <Typography variant="body2">
                              <i>
                                {" "}
                                {program.estimatedTuitionCost
                                  ? currencyFormat(program.estimatedTuitionCost)
                                  : "-"}
                              </i>
                            </Typography>
                          </Grid>
                          {program.estimatedTuitionCostOutOfState && (
                            <Grid item xs={12}>
                              <Typography variant="body2">
                                <b>Estimated Tuition + Fees (Out-of-State): </b>
                              </Typography>
                              <Typography variant="body2">
                                <i>
                                  {" "}
                                  {currencyFormat(
                                    program.estimatedTuitionCostOutOfState
                                  )}
                                </i>
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <b>University Funding Opportunities: </b>
                            </Typography>
                            <Typography variant="body2">
                              <i> {renderUniversityFundingOpportunities}</i>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <b>Program Funding Opportunities:</b>
                            </Typography>
                            <Typography variant="body2">
                              <i> {renderProgramFundingOpportunities}</i>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item lg={6} md={12}>
                      <Paper
                        variant="outlined"
                        sx={{
                          bgcolor: theme.palette.secondary.light,
                          p: 2,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Curriculum:</Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                              <b>Number of Courses / Units: </b>
                            </Typography>
                            <Typography variant="body2">
                              <i>
                                {" "}
                                {program.numCourses && program.numUnits
                                  ? `${program.numCourses} courses, ${program.numUnits} units`
                                  : program.numCourses
                                  ? `${program.numCourses} courses`
                                  : program.numUnits
                                  ? `${program.numUnits} units`
                                  : "-"}
                              </i>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                              <b>Internship? </b>
                            </Typography>
                            <Typography variant="body2">
                              <i>
                                {" "}
                                {program.internship ? "Yes" : "Not Required"}
                              </i>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              <b>Types of Courses:</b>
                            </Typography>
                            <List>{renderTypesOfCourses}</List>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        variant="outlined"
                        sx={{
                          bgcolor: theme.palette.secondary.light,
                          p: 2,
                          mt: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Post-Graduation Outcomes:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                              <b>Job Titles:</b>
                            </Typography>
                            <List>{renderTypesOfTitles}</List>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2">
                              <b>Types of Organizations:</b>
                            </Typography>
                            <List>{renderTypesOfOrgs}</List>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
