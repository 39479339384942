import { Goal, VirtualOption } from "./DegreePrograms";
import { State } from "./States";

// Interface that contains the metadata fields for degree programs that are displayed
// in the table of degree programs and/or used for sorting / filtering of degree
// programs. See `DegreePrograms.ts` for definitions
export interface DegreeTableData {
  id: string;
  university: string;
  programName: string;
  city: string;
  state: State;
  duration: number;
  deadline: Date | null;
  goal: Goal;
  rolling: boolean;
  virtual: VirtualOption;
}

// Function to create an instance of the above interface
export function createDegreeTableData(
  id: string,
  university: string,
  programName: string,
  city: string,
  state: State,
  duration: number,
  deadline: Date | null,
  goal: Goal,
  rolling: boolean,
  virtual: VirtualOption
): DegreeTableData {
  return {
    id,
    university,
    programName,
    city,
    state,
    duration,
    deadline,
    goal,
    rolling,
    virtual,
  };
}

// Interface that contains fields for use in head cells of the degree programs table
export interface HeadCell {
  disablePadding: boolean; // Padding around the field name in the header row?
  id: keyof DegreeTableData; // must be a field in the DegreeTableData interface
  label: string; // Display name
  align: "center" | "right" | "left" | "inherit" | "justify" | undefined;
  sort: boolean; // Is the table sortable according to this field?
}

// Head cells to display in the degree program table
export const degreeTableHeadCells: readonly HeadCell[] = [
  {
    id: "university",
    align: "left",
    disablePadding: false,
    label: "College / University",
    sort: true,
  },
  {
    id: "programName",
    align: "left",
    disablePadding: false,
    label: "Program Name",
    sort: false,
  },
  {
    id: "city",
    align: "left",
    disablePadding: false,
    label: "Location",
    sort: false,
  },
  {
    id: "duration",
    align: "left",
    disablePadding: false,
    label: "Duration",
    sort: true,
  },
  {
    id: "deadline",
    align: "left",
    disablePadding: false,
    label: "Nearest Application Deadline",
    sort: true,
  },
  {
    id: "goal",
    align: "left",
    disablePadding: false,
    label: "Program Goal",
    sort: false,
  },
];
