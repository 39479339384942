import { Box, Slider, Typography } from "@mui/material";

// Duration min and max should be labelled year if 1, and years if greater
function durationvaluetext(value: number) {
  let ret;
  value > 1 ? (ret = `${value} years`) : (ret = `${value} year`);
  return ret;
}

// Props for duration filter
interface DurationFilterProps {
  selectedDuration: number[];
  setSelectedDuration: React.Dispatch<React.SetStateAction<number[]>>;
}

// Functional component for duration filter slider. State variable lives in the parent
// DegreeProgramsTable component, but values are updated according to the slider in
// this component.
export default function DurationFilter(props: DurationFilterProps) {
  const { selectedDuration, setSelectedDuration } = props;

  // Call the setState function in the parent component
  const handleChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    setSelectedDuration(value as number[]);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Slider
        getAriaLabel={() => "Duration range"}
        onChange={handleChange}
        value={selectedDuration}
        valueLabelDisplay="auto"
        getAriaValueText={durationvaluetext}
        min={1}
        max={6}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2" color="textSecondary">{`Min: ${
          selectedDuration[0]
        } year${selectedDuration[0] > 1 ? `s` : ``}`}</Typography>
        <Typography variant="body2" color="textSecondary">{`Max: ${
          selectedDuration[1]
        } year${selectedDuration[1] > 1 ? `s` : ``}`}</Typography>
      </Box>
    </Box>
  );
}
