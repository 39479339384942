import {
  Button,
  ButtonGroup,
  Toolbar,
  Typography,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Menu as MenuIcon } from "@mui/icons-material";
import React from "react";

// Header functional component with button group navigation
function Header() {
  // Logic for handling the dropdown menu with site navigation buttons (Home, Learn, Build, Suggest)
  // when the window size is small enough to necessitate a more compact header
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Custom theming variables + breakpoints
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // If the dropdown menu is open, but the window is resized to allow for the full header,
  // this hook prevents a browser error in which the dropdown menu becomes anchored to an
  // un-rendered item
  React.useEffect(() => {
    if (!matches) setAnchorEl(null);
  }, [matches]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        overflowX: "hidden",
        position: "static",
      }}
    >
      <AppBar position="static">
        <Toolbar
          sx={{
            p: matches ? 1 : 7,
          }}
        >
          <Typography
            variant={matches ? "h6" : "h4"}
            component="div"
            sx={{ flexGrow: 1 }}
          >
            <Link
              color="inherit"
              underline="none"
              component={RouterLink}
              to="/"
            >
              Learning Engineering Commons
            </Link>
          </Typography>
          {matches ? (
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon color="inherit" />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                keepMounted={false}
                open={open}
                onClose={handleClose}
              >
                <MenuItem component={RouterLink} to={"/"}>
                  Home
                </MenuItem>
                <MenuItem component={RouterLink} to={"/learn"}>
                  Learn
                </MenuItem>
                <MenuItem component={RouterLink} to={"/build"}>
                  Build
                </MenuItem>
                <MenuItem component={RouterLink} to={"/suggest"}>
                  Suggest
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              color="inherit"
            >
              <Button component={RouterLink} to="/" size="large">
                Home
              </Button>
              <Button component={RouterLink} to="/learn">
                Learn
              </Button>
              <Button component={RouterLink} to="/build">
                Build
              </Button>
              <Button component={RouterLink} to="/suggest">
                Suggest
              </Button>
            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
