import * as React from "react";
import {
  DegreePrograms,
  Goal,
  goals,
  virtualOptions,
  VirtualOption,
} from "../Constants/DegreePrograms";
import {
  DegreeTableData,
  createDegreeTableData,
  degreeTableHeadCells,
} from "../Constants/Tables";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  FormLabel,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StateFilter from "./StateFilter";
import { states, State } from "../Constants/States";
import GoalFilter from "./GoalFilter";
import DurationFilter from "./DurationFilter";
import { Link as Routerlink } from "react-router-dom";
import VirtualFilter from "./VirtualFilter";

// Map all of the degree programs into a format that may be rendered into an MUI table
const rows: DegreeTableData[] = DegreePrograms.map((program) => {
  // Use the program's deadline if specified. Use today's date if unspecified and rolling
  // (for sorting purposes). Use null otherwise
  let deadline;
  if (program.deadline) deadline = program.deadline;
  else if (program.rolling) deadline = new Date();
  else deadline = null;

  return createDegreeTableData(
    program.id,
    program.university.name,
    program.programName,
    program.university.city,
    program.university.state,
    program.duration, // set arbitrarily high if unknown
    deadline,
    program.goal,
    program.rolling,
    program.virtual
  );
});

// Comparator function for use in sorting
export function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  order: Order
) {
  if (orderBy === "deadline") {
    // always sort null dates to the end
    if (!a[orderBy] && !b[orderBy]) return 0;
    if (!a[orderBy]) return 1;
    if (!b[orderBy]) return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return order === "desc" ? -1 : 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return order === "desc" ? 1 : -1;
  }
  return 0;
}

// Order may be ascending or descending
type Order = "asc" | "desc";

// Helper function to compare two programs in the specified direction (ascending
// or descending) according to the specified field (orderBy)
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date | boolean | null | State },
  b: { [key in Key]: number | string | Date | boolean | null | State }
) => number {
  return (a, b) => descendingComparator(a, b, orderBy, order);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Interface for the props to be used in the below DegreeProgramsTableHead component
interface DegreeProgramsTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DegreeTableData
  ) => void;
  order: Order;
  orderBy: string;
}

// Functional component for the header of the table that handles user clicks on
// sortable column headers
function DegreeProgramsTableHead(props: DegreeProgramsTableHeadProps) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: keyof DegreeTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead component="div" sx={{ width: "100%" }}>
      <TableRow component="div">
        {degreeTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            component="div"
          >
            <Box sx={{ display: "flex", alignContent: "center" }}>
              <Typography variant="subtitle2">{headCell.label}</Typography>
              {headCell.sort && ( // if the headcell is sortable
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Functional component for the table and its filter
export default function DegreeProgramsTable() {
  // Check if the filter options have values stored in session storage. This is
  // the case if the user has clicked on custom filter options and reloaded the page
  // or revisited it from another part of the website. These filter options will only
  // be removed if the user exits out of their browser.
  const storedOrder: Order = sessionStorage.getItem("order") as Order;
  const storedOrderBy: keyof DegreeTableData = sessionStorage.getItem(
    "orderBy"
  ) as keyof DegreeTableData;
  const storedPage: number = Number(sessionStorage.getItem("page"));
  const storedRowsPerPage: number = Number(
    sessionStorage.getItem("rowsPerPage")
  );
  // session storage only stores strings, so reformat into an array
  const storedGoals: Goal[] = sessionStorage
    .getItem("goals")
    ?.split(",") as Goal[];
  const storedDuration: number[] = JSON.parse(
    "[" + sessionStorage.getItem("duration") + "]"
  );
  let storedStates: string[] = sessionStorage
    .getItem("states")
    ?.split(",") as string[];
  // If the states list is empty, make sure we aren't providing an empty string as a state option
  if (storedStates && storedStates[0] === "") storedStates = [];
  const storedVirtualOptions: VirtualOption[] = sessionStorage
    .getItem("virtualOptions")
    ?.split(",") as VirtualOption[];

  // State variables for filtering list results, changing current page, and changing number of items
  // per page
  const [order, setOrder] = React.useState<Order>(
    storedOrder ? storedOrder : "asc"
  );
  const [orderBy, setOrderBy] = React.useState<keyof DegreeTableData>(
    storedOrderBy ? storedOrderBy : "deadline"
  );
  const [page, setPage] = React.useState(storedPage ? storedPage : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    storedRowsPerPage ? storedRowsPerPage : 10
  );
  const [filterGoals, setFilterGoals] = React.useState<Goal[]>(
    storedGoals ? storedGoals : [...goals]
  );
  const [filterDuration, setFilterDuration] = React.useState<number[]>(
    storedDuration && storedDuration[0] && storedDuration[1]
      ? storedDuration
      : [1, 6]
  );
  const [filterStates, setFilterStates] = React.useState<string[]>(
    storedStates ? storedStates : [...states].map((state) => state.abbreviation)
  );
  const [filterVirtualOptions, setFilterVirtualOptions] = React.useState<
    VirtualOption[]
  >(storedVirtualOptions ? storedVirtualOptions : [...virtualOptions]);
  const [displayedRows, setDisplayedRows] =
    React.useState<DegreeTableData[]>(rows);

  // MUI theming
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  // Today's date
  const today = new Date();

  // Whenever one of these sorting, filtering, and other table view options
  // are updated, store it in session storage
  React.useEffect(() => {
    sessionStorage.setItem("order", order);
  }, [order]);

  React.useEffect(() => {
    sessionStorage.setItem("orderBy", orderBy);
  }, [orderBy]);

  React.useEffect(() => {
    sessionStorage.setItem("page", page.toString());
  }, [page]);

  React.useEffect(() => {
    sessionStorage.setItem("rowsPerPage", rowsPerPage.toString());
  }, [rowsPerPage]);

  React.useEffect(() => {
    sessionStorage.setItem("goals", filterGoals.toString());
  }, [filterGoals]);

  React.useEffect(() => {
    sessionStorage.setItem("duration", filterDuration.toString());
  }, [filterDuration]);

  React.useEffect(() => {
    sessionStorage.setItem("virtualOptions", filterVirtualOptions.toString());
  }, [filterVirtualOptions]);

  React.useEffect(() => {
    sessionStorage.setItem("states", filterStates.toString());
  }, [filterStates]);

  // Update the sort order and key
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DegreeTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Update page
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Update rows per page
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Hook that listens to filter changes and updates the visible rows accordingly
  React.useEffect(() => {
    // check for new programs to be added in:
    const newRows = rows.filter((row) => {
      return (
        filterStates.some((e) => e === row.state.abbreviation) &&
        row.duration >= filterDuration[0] &&
        row.duration <= filterDuration[1] &&
        filterGoals.includes(row.goal) &&
        filterVirtualOptions.includes(row.virtual)
      );
    });
    setDisplayedRows([...newRows]);
  }, [filterStates, filterDuration, filterGoals, filterVirtualOptions]);

  // Sort the rows before they are rendered
  const sortedRows = stableSort(displayedRows, getComparator(order, orderBy));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayedRows.length) : 0;

  return (
    <Grid container sx={{ overflowX: "hidden" }}>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.dark,
            p: 3,
            ml: 5,
            mr: 5,
            mt: 3,
          }}
        >
          <Typography
            variant={matches ? "h6" : matcheslg ? "h4" : "h3"}
            sx={{
              width: "100%",
              ml: matches ? 0 : matcheslg ? 10 : 15,
              textAlign: matches ? "center" : "left",
            }}
          >
            Learning Engineering Degree Programs
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 5,
              width: "100%",
              textAlign: "center",
            }}
          >
            These degree programs provide students with an education that
            combines computer science, data science, and learning science. The
            weight that each program places on these pillars of learning
            engineering may vary, but they all enable graduates to work and
            practice as a learning engineer or to incorporate learning
            engineering principles in their work. Do you think that a program is
            missing?{" "}
            <Routerlink
              to="/suggest"
              style={{ color: theme.palette.common.black }}
            >
              Suggest it to us.
            </Routerlink>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper
          variant="outlined"
          sx={{ p: 2, m: 5, borderColor: theme.palette.primary.dark }}
        >
          <Typography variant="h6">Filter</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>
                <Typography color="textPrimary">Program Goal</Typography>
              </FormLabel>
              <GoalFilter
                selectedGoals={filterGoals}
                setSelectedGoals={setFilterGoals}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Typography color="textPrimary">Program Location</Typography>
              </FormLabel>
              <StateFilter
                selectedStates={filterStates}
                setSelectedStates={setFilterStates}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Typography color="textPrimary">Virtual?</Typography>
              </FormLabel>
              <VirtualFilter
                selectedVirtualOptions={filterVirtualOptions}
                setSelectedVirtualOptions={setFilterVirtualOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                <Typography color="textPrimary">Program Duration</Typography>
              </FormLabel>
              <DurationFilter
                selectedDuration={filterDuration}
                setSelectedDuration={setFilterDuration}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Paper
          variant="outlined"
          sx={{ m: 5, borderColor: theme.palette.primary.dark }}
        >
          <TableContainer>
            <Table
              aria-labelledby="Degree Programs Table"
              size="medium"
              component="div"
            >
              <DegreeProgramsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody component="div">
                {sortedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        component={Routerlink}
                        to={`/learn/degree-programs/${row.id}`}
                        sx={{ textDecoration: "none", height: 60 }}
                      >
                        <TableCell
                          component="div"
                          id={`degree-program-table-entry-${row.id}`}
                          scope="row"
                        >
                          {row.university}
                        </TableCell>
                        <TableCell component="div" align="left">
                          {row.programName}
                        </TableCell>
                        <TableCell component="div" align="left">
                          {row.city}, {row.state.abbreviation}
                        </TableCell>
                        <TableCell component="div" align="left">
                          {row.duration > 0
                            ? `${row.duration} year${
                                row.duration > 1 ? `s` : ``
                              }`
                            : `-`}
                        </TableCell>
                        <TableCell component="div" align="left">
                          {row.deadline && row.deadline >= today
                            ? row.deadline.toLocaleDateString("en-us", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : row.rolling
                            ? "Rolling"
                            : "-"}
                        </TableCell>
                        <TableCell component="div" align="left">
                          {row.goal}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    component="div"
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell component="div" colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={displayedRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
