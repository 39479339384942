import { State } from "./States";

// Interface that contains the metadata fields for degree programs
export interface DegreeProgram {
  id: string; // name of the school-integer count from zero (ie. if it's the second Stanford program to be added, it's id is "stanford-2")
  university: University; // full name of the university
  programName: string; // full name of the program
  link: string; // link to program website
  duration: number; // duration of program in years (if variable length, shorter end of range).
  durationMax?: number; // if variable program length, longer end of the range
  rolling: boolean; // rolling deadline or not. may still have other deadlines for aid / awards.
  deadline?: Date; // nearest application deadline (optional)
  goal: Goal; // PhD, MS, MA, etc.
  startTerm?: string[]; // Fall, Spring, Summer
  numCourses?: number; // total # of courses (optional)
  numUnits?: number; // total # of credits / units (optional)
  courses?: string[]; // examples of courses (optional) -- use categories defined at https://docs.google.com/document/d/1Xlav49gUBOVYcfPkL0KA5PShDzyOYLo9XR9Qs4vPwos/edit
  programFunding?: string[]; // program specific funding opportunities
  internship?: boolean; // is an internship required?
  virtual: VirtualOption;
  estimatedTuitionCost?: number; // tuition + fees (if public university, in-state)
  estimatedTuitionCostOutOfState?: number; // tuition + fees for out of state students (if public university)
  outcomes?: {
    // career outcomes, use categories defined at https://docs.google.com/document/d/1Kt57gYxAZvcWemWDaghKIaJrNdfxBJMYFWHRa779ccA/edit
    organization?: string[]; // host organization types (ie. tech, government, education)
    title?: string[]; // career titles (ie. curriculum designer, product manager)
  };
}

//
interface University {
  name: string; // full name of the university
  city: string; // city, state abbreviation (ie. Boston, MA)
  state: State; // State type (name and abbreviation), defined in `States.ts`
  funding: string[]; // funding opportunities (ie. financial aid, teaching assistantship)
}

// Options for the "goal" field in the DegreeProgram interface
export type Goal = "MA" | "MS" | "PhD" | "MSEd" | "EdD" | "Certificate" | "EdM";

// List of all of the above "goal" options
export const goals: Goal[] = [
  "MA",
  "MS",
  "PhD",
  "MSEd",
  "EdD",
  "Certificate",
  "EdM",
];

// Optiosn for the "virtual" field in the DegreeProgram interface
export type VirtualOption =
  | "Virtual Only"
  | "Virtual Option"
  | "Hybrid"
  | "Hybrid Option"
  | "In Person Only";

// List of all of the above "virtual" options
export const virtualOptions: VirtualOption[] = [
  "Virtual Only",
  "Virtual Option",
  "Hybrid",
  "Hybrid Option",
  "In Person Only",
];

// Storage dictionary for university programs. universityName can be an abbreviation,
// it only acts as the key for the dictionary and the string shown in the URL for that
// individual degree program's page on the site
let Universities: { [universityName: string]: University } = {};

Universities.stanford = {
  name: "Stanford University",
  city: "Stanford",
  state: {
    name: "California",
    abbreviation: "CA",
  },
  funding: ["Dean's Fellowships", "Scholarship Program", "Financial Aid"],
};
Universities.upenn = {
  name: "University of Pennsylvania",
  city: "Philadelphia",
  state: {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  funding: [
    "Merit-Based Scholarships",
    "Need-Based Scholarships",
    "Dean's Fellowship",
    "Dean's Scholarship",
    "Graduate Assistantships",
  ],
};
Universities.uncchapelhill = {
  name: "University of North Carolina at Chapel Hill",
  city: "Chapel Hill",
  state: {
    name: "North Carolina",
    abbreviation: "NC",
  },
  funding: ["Financial Aid"],
};
Universities.carnegiemellon = {
  name: "Carnegie Mellon University",
  city: "Pittsburgh",
  state: {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  funding: [
    "Financial Aid",
    "Research Assitantships",
    "Teaching Assistantships",
  ],
};
Universities.bostoncollege = {
  name: "Boston College",
  city: "Newton",
  state: {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  funding: [
    "Financial Aid",
    "Graduate Assistantships",
    "Scholarships",
    "Dean's Award",
  ],
};
Universities.wpi = {
  name: "Worcester Polytechnic Institute",
  city: "Worcester",
  state: {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  funding: ["Financial Aid", "Graduate Travel Award", "Scholarships"],
};
Universities.drexel = {
  name: "Drexel University",
  city: "Philadelphia",
  state: {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  funding: ["Financial Aid"],
};
Universities.northwestern = {
  name: "Northwestern University",
  city: "Evanston",
  state: {
    name: "Illinois",
    abbreviation: "IL",
  },
  funding: ["Financial Aid"],
};
Universities.harvard = {
  name: "Harvard University",
  city: "Cambridge",
  state: {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  funding: ["Financial Aid", "Grants"],
};
Universities.unt = {
  name: "University of North Texas",
  city: "Denton",
  state: {
    name: "Texas",
    abbreviation: "TX",
  },
  funding: ["Financial Aid", "Scholarships", "Grants"],
};
Universities.osu = {
  name: "The Ohio State University",
  city: "Columbus",
  state: {
    name: "Ohio",
    abbreviation: "OH",
  },
  funding: [
    "Fellowships",
    "Scholarships",
    "Financial Aid",
    "Graduate Associateships",
  ],
};
Universities.ncstate = {
  name: "North Carolina State University",
  city: "Raleigh",
  state: {
    name: "North Carolina",
    abbreviation: "NC",
  },
  funding: [
    "Scholarships",
    "Financial Aid",
    "Research Assistantships",
    "Teaching Assistantships",
    "Fellowships",
  ],
};
Universities.pepperdine = {
  name: "Pepperdine University",
  city: "Malibu",
  state: {
    name: "California",
    abbreviation: "CA",
  },
  funding: ["Scholarships"],
};
Universities.maryland = {
  name: "University of Maryland",
  city: "College Park",
  state: {
    name: "Maryland",
    abbreviation: "MD",
  },
  funding: ["Military Benefits", "Scholarships", "Grants"],
};
Universities.nyu = {
  name: "New York University",
  city: "New York City",
  state: {
    name: "New York",
    abbreviation: "NY",
  },
  funding: ["Financial Aid", "Scholarships", "Grants"],
};
Universities.american = {
  name: "American University",
  city: "Washington",
  state: {
    name: "District of Columbia",
    abbreviation: "DC",
  },
  funding: ["Merit Scholarships", "Student Employment", "Financial Aid"],
};
Universities.columbia = {
  name: "Columbia University",
  city: "New York City",
  state: {
    name: "New York",
    abbreviation: "NY",
  },
  funding: ["Scholarships", "Fellowships", "Grants", "Graduate Assistantships"],
};
Universities.wisconsin = {
  name: "University of Wisconsin-Madison",
  city: "Madison",
  state: {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  funding: ["Financial Aid", "Scholarships", "Graduate Assistantships"],
};
Universities.buffalo = {
  name: "University at Buffalo",
  city: "Buffalo",
  state: {
    name: "New York",
    abbreviation: "NY",
  },
  funding: [
    "Fellowships",
    "Scholarships",
    "Graduate Assistantships",
    "Teaching Assistantships",
    "Research Assistantships",
  ], // not available for certificate
};
Universities.tennessee = {
  name: "University of Tennessee Knoxville",
  city: "Knoxville",
  state: {
    name: "Tennessee",
    abbreviation: "TN",
  },
  funding: ["Scholarships", "Graduate Assistantships", "Fellowships"],
};
Universities.rutgers = {
  name: "Rutgers University",
  city: "New Brunswick",
  state: {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  funding: [
    "Financial Aid",
    "Scholarships",
    "Fellowships",
    "Graduate Assistantships",
  ],
};

// The Degree Programs which will be displayed on the site
export const DegreePrograms: DegreeProgram[] = [
  {
    id: "stanford-1",
    university: Universities.stanford,
    link: "https://ed.stanford.edu/eds",
    programName: "Education Data Science",
    duration: 2,
    rolling: false,
    goal: "MS",
    startTerm: ["Fall"],
    numCourses: 18,
    numUnits: 51,
    internship: true,
    estimatedTuitionCost: 75949,
    virtual: "In Person Only",
    courses: [
      "Statistics",
      "Data Science",
      "Education",
      "Other Social Sciences",
      "Research",
      "Data Science Specialization",
    ],
    outcomes: {
      title: [],
      organization: [
        "Private Research Firms",
        "K-12 Education Agencies",
        "Higher Education",
        "Government / Policy Organizations",
        "Technology Companies",
        "Other General Purpose Corporations",
        "Education For-Profits",
      ],
    },
  },
  {
    id: "stanford-2",
    university: Universities.stanford,
    programName: "Learning Sciences and Technology Design",
    link: "https://ed.stanford.edu/academics/doctoral/lstd",
    duration: 4,
    rolling: false,
    durationMax: 5,
    goal: "PhD",
    startTerm: ["Fall"],
    numUnits: 135,
    programFunding: ["Fully funded for 5 years"],
    internship: false,
    estimatedTuitionCost: 177663,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Research",
      "Design",
      "Instructional Design",
      "Other Social Sciences",
    ],
    outcomes: {
      title: [
        "Instructor",
        "Researcher",
        "Instructional / Curriculum Designer",
        "Educational Evaluator",
        "Policy Maker",
      ],
      organization: [
        "Higher Education",
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
      ],
    },
  },
  {
    id: "upenn-1",
    university: Universities.upenn,
    programName: "Learning Sciences and Technologies",
    link: "https://www.gse.upenn.edu/academics/programs/learning-sciences-technology-phd",
    duration: 4,
    rolling: false,
    goal: "PhD",
    startTerm: ["Fall"],
    numCourses: 12,
    programFunding: ["Fully funded for 4 years"],
    internship: false,
    estimatedTuitionCost: 159352,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Data Science",
      "Research",
      "Design",
      "Emerging Technologies",
    ],
    outcomes: {
      title: ["Instructor", "Researcher"],
      organization: [
        "Higher Education",
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Non-Profit / Community Based Organizations",
      ],
    },
  },
  {
    id: "upenn-2",
    university: Universities.upenn,
    programName: "Learning Sciences and Technologies",
    link: "https://www.gse.upenn.edu/academics/programs/learning-sciences-technology-masters",
    duration: 1,
    rolling: true,
    deadline: new Date("February 15, 2023"),
    goal: "MSEd",
    startTerm: ["Fall"],
    numCourses: 10,
    internship: false,
    estimatedTuitionCost: 75576,
    virtual: "In Person Only",
    courses: ["Education", "Design", "Data Science"],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "Educational Administrator",
        "Instructor",
        "Software Developer",
        "Doctoral Student",
      ],
      organization: [
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Non-Profit / Community Based Organizations",
      ],
    },
  },
  {
    id: "upenn-3",
    university: Universities.upenn,
    programName: "Learning Analytics",
    link: "https://www.gse.upenn.edu/academics/programs/learning-analytics-online-masters",
    duration: 1.3,
    rolling: true,
    deadline: new Date("February 1, 2023"),
    goal: "MSEd",
    startTerm: ["Fall", "Spring"],
    numCourses: 10,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 48440,
    courses: ["Data Science", "Education"],
    outcomes: {
      title: ["Doctoral Student", "Researcher"],
      organization: [
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Government / Policy Organizations",
        "Non-Profit / Community Based Organizations",
        "Higher Education",
        "K-12 Education Agencies",
      ],
    },
  },
  {
    id: "uncchapelhill-1",
    university: Universities.uncchapelhill,
    programName:
      "Educational Innovation, Technology, and Entrepeneurship (MEITE)",
    link: "https://ed.unc.edu/academics/programs/educational-innovation-technology-and-entrepreneurship",
    duration: 1,
    rolling: false,
    deadline: new Date("April 11, 2023"),
    goal: "MA",
    startTerm: ["Fall", "Spring"],
    numCourses: 12,
    numUnits: 36,
    programFunding: ["Program Scholarships"],
    internship: true,
    estimatedTuitionCost: 30773.46,
    estimatedTuitionCostOutOfState: 57362.46,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Design",
      "Emerging Technologies",
      "Business / Entrepreneurship",
      "Data Science",
      "Statistics",
      "Emerging Technologies",
    ],
    outcomes: {
      organization: [
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Corporate Training",
        "Higher Education",
        "K-12 Education Agencies",
        "Education Publishing / Media",
        "Non-Profit / Community Based Organizations",
        "Private Research Firms",
      ],
    },
  },
  {
    id: "carnegiemellon-1",
    university: Universities.carnegiemellon,
    programName:
      "Masters of Educational Technology and Applied Learning Sciences (METALS)",
    link: "https://metals.hcii.cmu.edu/",
    duration: 1,
    rolling: false,
    goal: "MS",
    startTerm: ["Fall"],
    numCourses: 11,
    internship: false,
    estimatedTuitionCost: 74700,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Design",
      "Emerging Technologies",
      "Instructional Design",
      "Data Science",
      "Computer Science",
    ],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "Educational Evaluator",
        "Instructional / Curriculum Developer",
        "Project Manager",
        "Data Scientist",
        "UX Designer / Researcher",
        "Entrepreneur",
      ],
      organization: [
        "Technology Companies",
        "Education Publishing / Media",
        "Higher Education",
        "General Purpose Corporations",
        "Education For-Profits",
        "Private Research Firms",
      ],
    },
  },
  {
    id: "bostoncollege-1",
    university: Universities.bostoncollege,
    programName: "Learning Engineering",
    link: "https://www.bc.edu/bc-web/schools/lynch-school/academics/departments/dfe/ma-learning-engineering.html",
    duration: 1,
    rolling: true,
    goal: "MA",
    startTerm: ["Fall"],
    numCourses: 20,
    numUnits: 30,
    internship: true,
    estimatedTuitionCost: 48224,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Emerging Technologies",
      "Design",
      "Other Social Sciences",
    ],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "UX Designer / Researcher",
        "Educational Evaluator",
        "Instructional / Curriculum Developer",
      ],
      organization: [
        "K-12 Education Agencies",
        "Higher Education",
        "Museums / Cultural Institutions",
        "General Purpose Corporations",
        "Education For-Profits",
        "Non-Profit / Community Based Organizations",
      ],
    },
  },
  {
    id: "wpi-1",
    university: Universities.wpi,
    programName: "Learning Sciences & Technologies",
    link: "https://www.wpi.edu/academics/study/learning-sciences-technologies-phd",
    duration: 5,
    rolling: true,
    goal: "PhD",
    startTerm: ["Fall", "Spring"],
    numUnits: 60,
    programFunding: ["Presidential Fellowship"],
    internship: false,
    virtual: "Virtual Option",
    estimatedTuitionCost: 96900,
    courses: [
      "Computer Science",
      "Other Social Sciences",
      "Statistics",
      "Research",
      "Education",
    ],
    outcomes: {
      title: ["Educational Evaluator", "Instructor", "Entrepreneur"],
    },
  },
  {
    id: "wpi-2",
    university: Universities.wpi,
    programName: " Learning Sciences & Technologies",
    link: "https://www.wpi.edu/academics/study/learning-sciences-technologies-ms",
    duration: 2,
    rolling: true,
    goal: "MS",
    startTerm: ["Fall", "Spring"],
    numUnits: 33,
    internship: false,
    virtual: "Virtual Option",
    estimatedTuitionCost: 53250,
    courses: [
      "Computer Science",
      "Other Social Sciences",
      "Statistics",
      "Research",
      "Education",
    ],
    outcomes: {
      title: ["Educational Evaluator", "Instructor", "Entrepreneur"],
    },
  },
  {
    id: "drexel-1",
    university: Universities.drexel,
    programName: "Learning Technologies",
    link: "https://drexel.edu/soe/academics/graduate/learning-technologies/",
    duration: 2,
    rolling: false,
    deadline: new Date("March 6, 2023"),
    goal: "MS",
    startTerm: ["Fall", "Spring"],
    numCourses: 15,
    numUnits: 45,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 47115,
    courses: [
      "Education",
      "Instructional Design",
      "Data Science",
      "Other Social Sciences",
    ],
    outcomes: {
      title: [
        "Educational Administrator",
        "Technology Administrator",
        "Educational Evaluator",
        "Instructional / Curriculum Developer",
        "Instructional / Curriculum Designer",
        "Media Producer",
        "Technology Administrator",
      ],
    },
  },
  {
    id: "drexel-2",
    university: Universities.drexel,
    programName: "Learning Analytics",
    link: "https://drexel.edu/soe/academics/certificates/Learning-Analytics/",
    duration: 1,
    rolling: false,
    deadline: new Date("March 6, 2023"),
    goal: "Certificate",
    startTerm: ["Fall", "Spring"],
    numCourses: 3,
    numUnits: 9,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 12564,
    courses: ["Data Science", "Education"],
    outcomes: {
      title: [
        "Instructor",
        "Instructional / Curriculum Designer",
        "Instructional / Curriculum Developer",
        "Policy-Maker",
        "Educational Administrator",
      ],
      organization: [
        "K-12 Education Agencies",
        "Higher Education",
        "Government / Policy Organizations",
        "Corporate Training",
      ],
    },
  },
  {
    id: "northwestern-1",
    university: Universities.northwestern,
    programName: "Computer Science and Learning Sciences",
    link: "https://www.sesp.northwestern.edu/learning-sciences/phd-computer-science-learning-sciences/index.html",
    duration: 5.5,
    rolling: false,
    deadline: new Date("December 15, 2023"),
    goal: "PhD",
    startTerm: ["Fall"],
    numCourses: 15,
    programFunding: ["Fully Funded"],
    internship: false,
    estimatedTuitionCost: 102784,
    virtual: "In Person Only",
    courses: [
      "Design",
      "Computer Science",
      "Research",
      "Other Social Sciences",
      "Data Sciences",
      "Education",
    ],
    outcomes: {
      title: ["Software Developer", "Instructional / Curriculum Developer"],
      organization: ["Higher Education"],
    },
  },
  {
    id: "harvard-1",
    university: Universities.harvard,
    programName: "Learning Design, Innovation, and Technology",
    link: "https://www.gse.harvard.edu/masters/programs/ldit",
    duration: 1,
    rolling: false,
    goal: "EdM",
    startTerm: ["Summer"],
    numUnits: 42,
    internship: false,
    estimatedTuitionCost: 54768,
    virtual: "In Person Only",
    courses: [
      "Education",
      "Diversity, Equity, & Inclusion",
      "Emerging Technologies",
      "Design",
      "Other Social Sciences",
    ],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "Software Developer",
        "Media Producer",
        "Product Manager",
        "UX Designer / Researcher",
        "Instructor",
        "Project Manager",
        "Instructional / Curriculum Developer",
        "Educational Administrator",
      ],
      organization: [
        "Museums / Cultural Institutions",
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Corporate Training",
        "K-12 Education Agencies",
        "Higher Education",
      ],
    },
  },
  {
    id: "unt-1",
    university: Universities.unt,
    programName: "Learning Technologies",
    link: "https://lt.unt.edu/masters",
    duration: 1.2,
    durationMax: 2,
    rolling: true,
    deadline: new Date("April 15, 2023"),
    goal: "MS",
    startTerm: ["Fall", "Spring", "Summer"],
    numCourses: 12,
    numUnits: 36,
    internship: false,
    virtual: "Virtual Option",
    estimatedTuitionCost: 14845.12,
    estimatedTuitionCostOutOfState: 23574.76,
    courses: [
      "Media Production",
      "Research",
      "Education",
      "Other Social Sciences",
      "Emerging Technologies",
      "Computer Science",
      "Business / Entrepreneurship",
    ],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "Instructional / Curriculum Developer",
        "Technology Administrator",
        "Media Producer",
        "Project Manager",
      ],
    },
  },
  {
    id: "unt-2",
    university: Universities.unt,
    programName: "Learning Technologies",
    link: "https://lt.unt.edu/doctoral-programs",
    duration: 4,
    durationMax: 5,
    rolling: false,
    deadline: new Date("March 10, 2023"),
    goal: "PhD",
    startTerm: ["Fall", "Summer"],
    numUnits: 69,
    internship: false,
    virtual: "Virtual Option",
    estimatedTuitionCost: 36688,
    estimatedTuitionCostOutOfState: 58936.9,
    courses: [
      "Other Social Sciences",
      "Education",
      "Emerging Technologies",
      "Instructional Design",
      "Design",
      "Computer Science",
      "Research",
    ],
    outcomes: {
      title: [
        "Instructor",
        "Instructional / Curriculum Designer",
        "Technology Administrator",
        "Educational Administrator",
        "Researcher",
      ],
      organization: [
        "K-12 Education Organization",
        "Technology Companies",
        "Corporate Training",
        "General Purpose Corporations",
        "Education For-Profits",
        "Higher Education",
      ],
    },
  },
  {
    id: "osu-1",
    university: Universities.osu,
    programName: "Educational Studies, Learning Technologies",
    link: "https://ehe.osu.edu/graduate/learning-technologies/learning-technologies-phd",
    duration: 5,
    rolling: false,
    deadline: new Date("December 1, 2023"),
    goal: "PhD",
    startTerm: ["Fall"],
    numUnits: 51,
    internship: false,
    estimatedTuitionCost: 66875,
    estimatedTuitionCostOutOfState: 199880,
    virtual: "In Person Only",
    courses: [
      "Research",
      "Statistics",
      "Other Social Sciences",
      "Education",
      "Emerging Technologies",
      "Instructional Design",
    ],
    outcomes: {
      title: [
        "Instructor",
        "Researcher",
        "Educational Administrator",
        "Technology Administrator",
      ],
      organization: [
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Higher Education",
        "Private Research Firms",
      ],
    },
  },
  {
    id: "ncstate-1",
    university: Universities.ncstate,
    programName: "Learning Design and Technology",
    link: "https://ced.ncsu.edu/graduate/programs/masters/learning-design-and-technology/",
    duration: 2,
    durationMax: 3,
    rolling: true,
    goal: "MS",
    startTerm: ["Summer", "Fall"],
    numCourses: 12,
    numUnits: 36,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 19190.44,
    estimatedTuitionCostOutOfState: 57602.44,
    courses: [
      "Instructional Design",
      "Emerging Technologies",
      "Education",
      "Computer Science",
      "Data Science",
      "Research",
    ],
    outcomes: {
      title: ["Researcher", "Doctoral Student"],
      organization: ["Higher Education", "Private Research Firms"],
    },
  },
  {
    id: "ncstate-2",
    university: Universities.ncstate,
    programName:
      "Teacher Education and Learning Sciences: Learning Design and Technology",
    link: "https://ced.ncsu.edu/graduate/programs/doctoral/learning-design-and-technology/",
    rolling: false,
    duration: 5, // needs confirmation
    deadline: new Date("December 1, 2023"),
    goal: "PhD",
    startTerm: ["Summer", "Fall"],
    numUnits: 72,
    internship: false,
    virtual: "Hybrid",
    courses: [
      "Education",
      "Diversity, Equity, & Inclusion",
      "Research",
      "Data Science",
      "Computer Science",
      "Other Social Sciences",
      "Emerging Technologies",
      "Media Production",
      "Instructional Design",
    ],
    outcomes: {
      organization: [
        "K-12 Education Agencies",
        "Higher Education",
        "Technology Companies",
        "General Purpose Corporations",
        "Education For-Profits",
        "Non-Profit / Community Based Organizations",
        "Government / Policy Organizations",
      ],
    },
  },
  {
    id: "ncstate-3",
    university: Universities.ncstate,
    programName: "Learning Analytics",
    link: "https://online-distance.ncsu.edu/program/learning-analytics/",
    duration: 1,
    rolling: false,
    deadline: new Date("March 15, 2023"),
    goal: "Certificate",
    startTerm: ["Fall", "Spring", "Summer"],
    numUnits: 12,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 7906.74,
    courses: ["Education", "Data Science"],
    outcomes: {
      title: [
        "Educational Evaluator",
        "Instructional / Curriculum Designer",
        "Instructional / Curriculum Developer",
        "Educational Administrator",
      ],
      organization: ["Technology Companies", "K-12 Education Agencies"],
    },
  },
  {
    id: "pepperdine-1",
    university: Universities.pepperdine,
    programName: "Learning Technologies",
    link: "https://gsep.pepperdine.edu/doctorate-learning-technologies/",
    duration: 3,
    rolling: false,
    deadline: new Date("June 26, 2023"),
    goal: "EdD",
    startTerm: ["Fall"],
    numUnits: 51,
    internship: false,
    virtual: "Hybrid Option",
    estimatedTuitionCost: 113085,
    courses: [
      "Business / Entrepreneurship",
      "Diversity, Equity, & Inclusion",
      "Statistics",
      "Other Social Sciences",
      "Emerging Technologies",
      "Education",
      "Research",
    ],
    outcomes: {
      title: ["Educational Administrator", "Software Developer"],
      organization: [
        "General Purpose Corporations",
        "Education For-Profits",
        "Corporate Training",
        "Technology Companies",
      ],
    },
  },
  {
    id: "maryland-1",
    university: Universities.maryland,
    programName: "Learning Design & Technology",
    link: "https://www.umgc.edu/online-degrees/masters/learning-design-technology",
    duration: 2, // needs confirmation
    rolling: true,
    goal: "MS",
    startTerm: ["Fall", "Spring", "Summer"],
    numUnits: 36,
    internship: false,
    virtual: "Virtual Only", // needs confirmation
    estimatedTuitionCost: 18504,
    estimatedTuitionCostOutOfState: 23724,
    courses: [
      "Education",
      "Instructional Design",
      "Emerging Technologies",
      "Data Science",
    ],
  },
  {
    id: "nyu-1",
    university: Universities.nyu,
    programName: "Learning Technology and Experience Design",
    link: "https://steinhardt.nyu.edu/degree/ma-learning-technology-experience-design",
    duration: 1.5,
    durationMax: 2,
    rolling: false,
    deadline: new Date("October 1, 2023"),
    goal: "MA",
    startTerm: ["Fall", "Spring", "Summer"],
    numUnits: 36,
    internship: false,
    estimatedTuitionCost: 77244,
    virtual: "In Person Only",
    courses: [
      "Design",
      "Data Science",
      "Education",
      "Emerging Technologies",
      "Other Social Sciences",
    ],
    outcomes: {
      title: [
        "UX Designer / Researcher",
        "Instructional / Curriculum Designer",
        "Media Producer",
        "Data Scientist",
        "Researcher",
        "Instructor",
        "Educational Evaluator",
      ],
      organization: [
        "Technology Companies",
        "Education Publishing / Media Companies",
        "K-12 Education Agencies",
        "Higher Education",
        "Museums / Cultural Institutions",
      ],
    },
  },
  {
    id: "american-1",
    university: Universities.american,
    programName: "Instructional Design & Learning Analytics",
    link: "https://www.american.edu/provost/ogps/professional-studies/masters/",
    duration: 1.6,
    rolling: false, // unknown
    goal: "MS",
    numUnits: 30,
    internship: false,
    virtual: "Virtual Only",
    outcomes: {
      title: [
        "Educational Administrator",
        "Technology Administrator",
        "Data Scientist",
        "Educational Evaluator",
        "Instructional / Curriculum Designer",
      ],
    },
  },
  {
    id: "columbia-1",
    university: Universities.columbia,
    programName: "Learning Analytics",
    link: "https://www.tc.columbia.edu/human-development/learning-analytics/degrees--requirements/learning-analytics-ms/",
    duration: 1,
    rolling: false,
    deadline: new Date("April 15, 2023"),
    goal: "MS",
    startTerm: ["Fall"],
    numUnits: 32,
    internship: false,
    estimatedTuitionCost: 55107,
    virtual: "In Person Only",
    courses: [
      "Data Science",
      "Education",
      "Other Social Sciences",
      "Statistics",
    ],
    outcomes: {
      organization: [
        "Technology Companies",
        "K-12 Education Agencies",
        "Government / Policy Organizations",
      ],
    },
  },
  {
    id: "wisconsin-1",
    university: Universities.wisconsin,
    programName: "Educational Psychology - Learning Analytics",
    link: "https://learninganalytics.education.wisc.edu/",
    duration: 2,
    rolling: false,
    deadline: new Date("May 1, 2023"),
    goal: "MS",
    startTerm: ["Summer"],
    numUnits: 30,
    programFunding: ["Committed Scholars Program"],
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 30000,
    courses: [
      "Education",
      "Statistics",
      "Research",
      "Data Science",
      "Other Social Sciences",
      "Instructional Design",
    ],
    outcomes: {
      title: [
        "Instructional / Curriculum Designer",
        "Instructional / Curriculum Developer",
        "Researcher",
        "Educational Evaluator",
      ],
      organization: [
        "K-12 Education Agency",
        "Higher Education",
        "Government / Policy Organizations",
        "Technology Companies",
        "Corporate Training",
      ],
    },
  },
  {
    id: "buffalo-1",
    university: Universities.buffalo,
    programName: "International Education Data Analysis",
    link: "https://ed.buffalo.edu/leadership/academics/ac/ed-data-analysis.html",
    duration: 1,
    durationMax: 2,
    rolling: true,
    deadline: new Date("April 1, 2023"),
    goal: "Certificate",
    startTerm: ["Fall", "Spring"],
    numUnits: 13,
    internship: false,
    virtual: "In Person Only",
    courses: ["Statistics", "Education", "Research", "Data Science"],
    outcomes: {
      title: ["Data Scientist", "Researcher"],
      organization: [
        "Government / Policy Organizations",
        "Non-Profit / Community Based Organizations",
        "K-12 Education Agencies",
        "Higher Education",
      ],
    },
  },
  {
    id: "tennessee-1",
    university: Universities.tennessee,
    programName: "Educational Data Science",
    link: "https://volsonline.utk.edu/programs-degrees/educational-data-science-gc/",
    duration: 1, // needs confirmation
    rolling: false,
    goal: "Certificate",
    startTerm: ["Fall", "Spring"],
    numCourses: 4,
    numUnits: 12,
    internship: false,
    virtual: "Virtual Only",
    estimatedTuitionCost: 8400,
    estimatedTuitionCostOutOfState: 9300,
    courses: [
      "Data Science",
      "Education",
      "Emerging Technologies",
      "Computer Science",
    ],
  },
  {
    id: "rutgers-1",
    university: Universities.rutgers,
    programName: "Educational Statistics, Measurement, and Evaluation",
    link: "https://gse.rutgers.edu/degree/ed-m-in-educational-statistics-measurement-evaluation/",
    duration: 2,
    rolling: false,
    deadline: new Date("October 15, 2023"),
    goal: "EdM",
    startTerm: ["Fall", "Spring"],
    numUnits: 33,
    internship: false,
    estimatedTuitionCost: 45910,
    estimatedTuitionCostOutOfState: 72118,
    virtual: "In Person Only",
    courses: [
      "Statistics",
      "Research",
      "Education",
      "Data Science",
      "Other Social Sciences",
    ],
    outcomes: {
      title: ["Researcher", "Data Scientist"],
      organization: [
        "Private Research Firms",
        "K-12 Education Agencies",
        "General-Purpose Corporations",
      ],
    },
  },
  {
    id: "rutgers-2",
    university: Universities.rutgers,
    programName:
      "Learning, Cognition, Instruction, and Development Concentration with Specialization in Statistics and Measurement",
    link: "https://gse.rutgers.edu/degree/phd-lcid-specialization-in-statistics-measurement/",
    duration: 5,
    durationMax: 6,
    rolling: false,
    deadline: new Date("December 1, 2023"),
    goal: "PhD",
    startTerm: ["Fall"],
    numUnits: 72,
    internship: false,
    estimatedTuitionCost: 114775,
    estimatedTuitionCostOutOfState: 180295,
    virtual: "In Person Only",
    courses: ["Education", "Research", "Other Social Sciences", "Statistics"],
    outcomes: {
      title: ["Data Scientist", "Researcher"],
      organization: [
        "Higher Education",
        "Government / Policy Organizations",
        "Non-Profit / Community Based Organizations",
      ],
    },
  },
];
