// Interface that contains the metadata fields for public datasets
export interface Dataset {
  title: string; // the name of the dataset
  link: string; // URL string to access dataset
  organization: string; // the organization that created the data set
  description: string; // brief description of relevance / purpose
  location?: string; // the location of the subjects in the data set (optional)
  scale?: ScaleOption[]; // the scale of the study, see options below (optional)
  collectionYears?: string; // year(s) in which data collected (optional)
  lastUpdated?: string; // year that data was last updated (optional)
  ageGroup?: string; // age group of student subjects involved (optional)
  useCases?: UseCase[]; // projects / papers that use the dataset (optional)
  download?: boolean; // is it available for download? (optional)
  API?: boolean; // is there an API available to access the data? (optional)
}

// Interface that contains the metadata fields for collections of public datasets
export interface DatasetCollection {
  title: string; // title of collection
  link: string; // URL string to access collection
  description: string; // brief description of types of datasets included
}

// Options for the "scale" field in the Dataset interface
type ScaleOption =
  | "International"
  | "National"
  | "Regional"
  | "State"
  | "Commuting Zone"
  | "County"
  | "District"
  | "Metropolitan Area"
  | "City / Town"
  | "School";

// Interface for the useCase field in the Dataset interface
export interface UseCase {
  title: string; // name of project / paper
  link: string; // URL string to access project / paper
}

// The Datasets which will be displayed on the site
export const datasets: Dataset[] = [
  {
    title: "Student Engagement Dataset",
    organization: "Boston University, University of Massachusetts Amherst",
    location: "Massachusetts, United States",
    scale: ["School"],
    lastUpdated: "2021",
    ageGroup: "College",
    description:
      "A video dataset of college students solving math problems on the educational platform MathSpring.org with a front facing camera collecting visual feedback of student gestures. The video dataset is annotated to indicate whether students’ attention at specific frames is engaged or wandering. In addition, a computer vision module determines the extent of student engagement during remote learning",
    download: true,
    API: false,
    link: "https://openaccess.thecvf.com/content/ICCV2021W/ABAW/papers/Delgado_Student_Engagement_Dataset_ICCVW_2021_paper.pdf",
  },
  {
    title: "Programme for International Student Assessment (PISA)",
    organization:
      "Organization for Economic Co-operation and Development (OECD)",
    location: "Global",
    scale: ["International"],
    collectionYears: "Every 3 years beginning in 2000",
    lastUpdated: "2018",
    ageGroup: "15 year olds",
    description:
      "Application of reading, mathematics, and science knowledge and skills to meet real life challenges.",
    download: true,
    API: false,
    link: "https://www.oecd.org/pisa/data/",
  },
  {
    title: "Youth Risk Behavior Surveillance System (YRBSS)",
    organization: "Centers for Disease Control and Prevention (CDC)",
    location: "United States",
    scale: ["National", "State", "District"],
    collectionYears: "Every 2 years beginning in 1991",
    lastUpdated: "2019",
    ageGroup: "12-18+",
    description:
      "Monitors six categories of health-related behaviors that contribute to the leading causes of death and disability among youth and adults",
    download: true,
    API: false,
    link: "https://www.cdc.gov/healthyyouth/data/yrbs/index.htm",
  },
  {
    title: "Progress in International Reading Literacy Study (PIRLS) [US]",
    organization:
      "National Center for Education Statistics (NCES) + International Association for the Evaluation of Educational Achievement (IEA)",
    location: "United States",
    scale: ["National"],
    collectionYears: "Every 5 years beginning in 2001",
    lastUpdated: "2016",
    ageGroup: "4th grade",
    description:
      "Measures reading achievement at the fourth-grade level, as well as school and teacher practices related to instruction.",

    download: true,
    API: false,
    link: "https://nces.ed.gov/surveys/pirls/",
  },
  {
    title:
      "Progress in International Reading Literacy Study (PIRLS) [International]",
    organization:
      "International Association for the Evaluation of Educational Achievement (IEA)",
    location: "Global",
    scale: ["International"],
    collectionYears: "Every 5 years beginning in 2001",
    lastUpdated: "2016",
    ageGroup: "4th grade",
    description:
      "Measures reading achievement at the fourth-grade level, as well as school and teacher practices related to instruction.",

    download: true,
    API: false,
    link: "https://timssandpirls.bc.edu/pirls-landing.html",
  },
  {
    title: "Trends in International Mathematics and Science Study (TIMSS) [US]",
    organization:
      "National Center for Education Statistics (NCES) + International Association for the Evaluation of Educational Achievement (IEA)",
    location: "United States",
    scale: ["National"],
    collectionYears: "Every 4 years since 1995",
    lastUpdated: "2019",
    ageGroup: "4th and 8th grade",
    description: "Monitors trends in mathematics and science achievement.",
    download: true,
    API: false,
    link: "https://nces.ed.gov/timss/",
  },
  {
    title:
      "Trends in International Mathematics and Science Study (TIMSS) [International]",
    organization:
      "International Association for the Evaluation of Educational Achievement (IEA)",
    location: "Global",
    scale: ["International"],
    collectionYears: "Every 4 years since 1995",
    lastUpdated: "2019",
    ageGroup: "4th and 8th grade",
    description: "Monitors trends in mathematics and science achievement.",
    download: true,
    API: false,
    link: "https://timssandpirls.bc.edu/timss-landing.html",
  },
  {
    title:
      "Trends in International Mathematics and Science Study Advanced (TIMSS Advanced) [US]",
    organization:
      "National Center for Education Statistics (NCES) + International Association for the Evaluation of Educational Achievement (IEA)",
    location: "United States",
    scale: ["National"],
    collectionYears: "1995, 2015",
    lastUpdated: "2015",
    ageGroup: "Final year of secondary school",
    description: "Studies achievement in advanced mathematics and physics. ",
    download: true,
    API: false,
    link: "https://nces.ed.gov/timss/",
  },
  {
    title:
      "Trends in International Mathematics and Science Study Advanced (TIMSS Advanced) [International]",
    organization:
      "International Association for the Evaluation of Educational Achievement (IEA)",
    location: "Global",
    scale: ["International"],
    collectionYears: "1995, 2008, 2015",
    lastUpdated: "2015",
    ageGroup: "Final year of secondary school",
    description: "Studies achievement in advanced mathematics and physics.",
    download: true,
    API: false,
    link: "https://timssandpirls.bc.edu/timss-landing.html",
  },
  {
    title: "Stanford Education Data Archive (SEDA)",
    organization: "Stanford University",
    location: "United States",
    scale: [
      "State",
      "Metropolitan Area",
      "Commuting Zone",
      "County",
      "District",
      "School",
    ],
    collectionYears: "2019, 2022",
    lastUpdated: "2022",
    ageGroup: "Grades 3-8",
    description:
      "Includes measures of academic achievement, achievement gaps, school and neighborhood racial and socioeconomic composition, school and neighborhood racial and socioeconomic segregation patterns, and other features of the schooling system.",
    download: true,
    API: false,
    link: "https://exhibits.stanford.edu/data/catalog/db586ns4974",
  },
  {
    title: "Open University Learning Analytics Dataset (OULAD)",
    organization: "Knowledge Media Institute, the Open University",
    location: "United Kingdom",
    scale: ["National"],
    collectionYears: "2013, 2014",
    lastUpdated: "2015",
    ageGroup: "All ages ",
    description:
      "Contains data about courses, students and their interactions with Virtual Learning Environment (VLE) for seven selected courses",
    useCases: [
      {
        link: "https://www.sciencedirect.com/science/article/pii/S0950705118303496",
        title:
          "Are we meeting a deadline? classification goal achievement in time in the presence of imbalanced data",
      },
    ],
    download: true,
    API: false,
    link: "https://analyse.kmi.open.ac.uk/open_dataset",
  },
  {
    title: "National Assessment of Educational Progress (NAEP)",
    organization: "National Center for Education Statistics (NCES)",
    location: "United States",
    scale: ["National", "State"],
    collectionYears: "Every 2 years beginning in 1990",
    lastUpdated: "2016",
    ageGroup: "4th and 8th grade",
    description:
      "Provides important information about student achievement and learning experiences in various subjects.",
    download: true,
    API: false,
    link: "https://nces.ed.gov/nationsreportcard/data/",
  },
  {
    title: "Learning@Scale",
    organization: "Arizona State University",
    location: "Tempe, Arizona",
    scale: ["School"],
    ageGroup: "College-aged",
    description:
      "Data on five foundational elements that provide a comprehensive understanding of the ASU student learning experience: student profile, student trajectory, course profile, discussion boards, and written assignments.",
    link: "https://learningatscale.asu.edu/",
  },
  {
    title: "CEE Datahub",
    organization: "American Institutes for Research",
    location: "California, Florida, New York, Tennessee, Texas, Washington",
    scale: ["State", "District", "School", "County"],
    collectionYears: "2016-2023",
    description:
      "Provides a rich foundation for studying responses to the pandemic. It draws on datasets across the sectors of education, health, housing, social services, employment, and technology access",
    download: true,
    API: false,
    link: "https://cee.airprojects.org/",
  },
  {
    title: "Civil Rights Data Collection (CRDC)",
    organization: "US Department of Education",
    location: "United States",
    scale: ["National", "State", "District", "School"],
    collectionYears: "Every 2 years since 1968",
    lastUpdated: "2018",
    ageGroup: "K-12",
    description:
      "Collects a variety of information including student enrollment and educational programs and services, most of which is disaggregated by race/ethnicity, sex, limited English proficiency, and disability",
    download: true,
    API: false,
    link: "https://ocrdata.ed.gov/",
  },
  {
    title: "GSM8K",
    organization: "OpenAI",
    collectionYears: "2021",
    lastUpdated: "2021",
    ageGroup: "Grade school",
    description:
      "GSM8K consists of 8.5K high quality grade school math problems created by human problem writers and segmented into 7.5k training problems and 1k test problems. These problems take between 2 and 8 steps to solve, and solutions primarily involve performing a sequence of elementary calculations using basic arithmetic operations (+ - / *) to reach the final answer.",
    download: true,
    API: false,
    link: "https://paperswithcode.com/dataset/gsm8k",
  },
];

// The Dataset Collections which will be displayed on the site
export const datasetCollections: DatasetCollection[] = [
  {
    title: "DataShop@CMU",
    link: "https://pslcdatashop.web.cmu.edu/",
    description:
      "A data repository and web application for learning science researchers that provides secure data storage plus analysis and visualization tools.",
  },
  {
    title: "ASSISTmentsData",
    link: "https://sites.google.com/site/assistmentsdata/datasets?authuser=0",
    description:
      "A collection of datasets related to grade school students’ interactions with an online learning platform.",
  },
  {
    title: "Kaggle",
    link: "https://www.kaggle.com/",
    description:
      "Contains over 50,000 public datasets and 400,000 public notebooks for data analysis.",
  },
  {
    title: "Hugging Face",
    link: "https://huggingface.co/datasets",
    description:
      "A collection of datasets, models, and more resources for developing AI models and conducting research.",
  },
  {
    title: "LearnSphere",
    link: "http://learnsphere.org/explore.html",
    description:
      "A collection of tools, including data repositories, for learning research.",
  },
  {
    title: "ICPSR",
    link: "https://www.icpsr.umich.edu/web/pages/ICPSR/index.html",
    description:
      "Maintains a data archive of more than 250,000 files of research in the social and behavioral sciences",
  },
  {
    title: "IPEDS",
    link: "https://nces.ed.gov/ipeds/",
    description:
      "A collection of data and general information on U.S. colleges, universities, and technical and vocational institutions.",
  },
  {
    title: "LDbase",
    link: "https://ldbase.org/",
    description:
      "An open science resource for the educational and developmental science scientific communities, providing a secure place to store and access data, as well access materials about aspects of data management and analyses.",
  },
  {
    title: "Google Dataset Search",
    link: "https://datasetsearch.research.google.com/",
    description: "Google’s search engine for datasets.",
  },
  {
    title: "Roper Center",
    link: "https://ropercenter.cornell.edu/",
    description:
      "A repository of public opinion and survey data operated out of Cornell University.",
  },
  {
    title: "Our World in Data",
    link: "https://ourworldindata.org/",
    description:
      "Free and open source charts and datasets on the world’s largest problems.",
  },
  {
    title: "NCES International Data Explorer",
    link: "https://nces.ed.gov/surveys/international/ide/",
    description:
      "A platform for exploring student and adult performance on international assessments.",
  },
  {
    title: "Papers with Code",
    link: "https://paperswithcode.com/",
    description:
      "A free and open resource with Machine Learning papers, code, datasets, methods and evaluation tables.",
  },
  {
    title: "OER Commons",
    link: "https://www.oercommons.org/browse?batch_size=20&sort_by=title&view_mode=summary&f.general_subject=mathematics&f.material_types=assessment",
    description: "A public digital library of open educational resources.",
  },
];
