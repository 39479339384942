import { ThemeOptions, createTheme } from "@mui/material/styles";

// Custom theme options for MUI (use https://zenoo.github.io/mui-theme-creator/ to experiment)
export const LECommonsTheme: ThemeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#01579b",
    },
    secondary: {
      main: "#607d8b",
      light: "#f5f5f5",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ed6c02",
    },
  },
  typography: {
    fontFamily: "Raleway",
    h1: {
      fontFamily: "Raleway",
    },
    h2: {
      fontFamily: "Raleway",
    },
    h3: {
      fontFamily: "Raleway",
    },
    overline: {
      fontFamily: "Roboto",
    },
    caption: {
      fontFamily: "Roboto",
    },
    body1: {
      fontFamily: "Roboto",
    },
    body2: {
      fontFamily: "Roboto",
    },
    h6: {
      fontFamily: "Raleway",
    },
    h5: {
      fontFamily: "Raleway",
    },
    h4: {
      fontFamily: "Raleway",
    },
    subtitle2: {
      fontFamily: "Lato",
    },
    subtitle1: {
      fontFamily: "Lato",
    },
    button: {
      cursor: "pointer",
      fontFamily: "Roboto",
    },
  },
});
