// File for any helper functions that are used across different components in the application

// Function that takes an array of strings and turns it into a comma separated list (with spaces)
export function createCommaSeparatedList(list: string[]) {
  const listLength = list.length;
  return list.map((item, index) => {
    if (listLength > 1 && index < listLength - 1) return item + ", ";
    return item;
  });
}
