import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { VirtualOption, virtualOptions } from "../Constants/DegreePrograms";
import React from "react";

// Props for virtual filter
interface VirtualFilterProps {
  selectedVirtualOptions: VirtualOption[];
  setSelectedVirtualOptions: React.Dispatch<
    React.SetStateAction<VirtualOption[]>
  >;
}

// Functional component for viertual filter checkboxes. State variable lives in the parent
// DegreeProgramsTable component, but values are updated according to the boxes in
// this component.
export default function VirtualFilter(props: VirtualFilterProps) {
  const { selectedVirtualOptions, setSelectedVirtualOptions } = props;
  const allSelected = virtualOptions.length === selectedVirtualOptions.length;

  // Call the setState function in the parent component
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // special logic for "select all" option
    if (event.target.name === "SA") {
      if (allSelected) setSelectedVirtualOptions([]);
      else setSelectedVirtualOptions([...virtualOptions]);
    } else {
      const clickedOption = event.target.name as VirtualOption;
      const currentSelectedVirtualOptions = selectedVirtualOptions;

      // Add the item if it is being checked, remove it otherwise
      if (checked) {
        const newOptions = currentSelectedVirtualOptions.concat([
          clickedOption,
        ]);
        setSelectedVirtualOptions([...newOptions]);
      } else {
        const index = currentSelectedVirtualOptions.indexOf(clickedOption);
        currentSelectedVirtualOptions.splice(index, 1);
        setSelectedVirtualOptions([...currentSelectedVirtualOptions]);
      }
    }
  };

  // Function to check if the box should be marked checked or not
  const getChecked = (name: VirtualOption) => {
    return selectedVirtualOptions.includes(name);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="Virtual Only"
              checked={getChecked("Virtual Only")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Virtual Only
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="Virtual Option"
              checked={getChecked("Virtual Option")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Virtual Option
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="Hybrid"
              checked={getChecked("Hybrid")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Hybrid
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="Hybrid Option"
              checked={getChecked("Hybrid Option")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Hybrid Option
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="In Person Only"
              checked={getChecked("In Person Only")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              In Person Only
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox onChange={handleChange} name="SA" checked={allSelected} />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Select All
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}
