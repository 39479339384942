import { Route, HashRouter as Router, Routes } from "react-router-dom";
import Header from "../Header/Header";
import { Grid, ThemeProvider } from "@mui/material";
import { LECommonsTheme } from "../Constants/Theme";
import Home from "../Home/Home";
import Learn from "../Learn/Learn";
import Build from "../Build/Build";
import Suggest from "../Suggest/Suggest";
import IndividualDegreeProgram from "../Learn/IndividualDegreeProgram";
import DegreeProgramsTable from "../Learn/DegreeProgramsTable";

// This is the base component for the website. If you are adding a new route to the site, do so
// in the <Routes> tag below
function App() {
  return (
    <Router basename={"/"}>
      <ThemeProvider theme={LECommonsTheme}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Routes>
              <Route key="home" path="/" element={<Home />} />
              <Route key="learn" path="/learn" element={<Learn />} />
              <Route
                key="degree-programs"
                path="/learn/degree-programs"
                element={<DegreeProgramsTable />}
              />
              <Route key="build" path="/build" element={<Build />} />
              <Route key="suggest" path="/suggest" element={<Suggest />} />
              <Route
                key="individual-degree-program"
                path="/learn/degree-programs/:id"
                element={<IndividualDegreeProgram />}
              />
            </Routes>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Router>
  );
}

export default App;
