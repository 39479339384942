import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { Goal, goals } from "../Constants/DegreePrograms";
import React from "react";

// Props for goal filter
interface GoalFilterProps {
  selectedGoals: Goal[];
  setSelectedGoals: React.Dispatch<React.SetStateAction<Goal[]>>;
}

// Functional component for goal filter checkboxes. State variable lives in the parent
// DegreeProgramsTable component, but values are updated according to the boxes in
// this component.
export default function GoalFilter(props: GoalFilterProps) {
  const { selectedGoals, setSelectedGoals } = props;
  const allSelected = goals.length === selectedGoals.length;

  // Call the setState function in the parent component
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // Special logic for "select all" option
    if (event.target.name === "SA") {
      if (allSelected) setSelectedGoals([]);
      else setSelectedGoals([...goals]);
    } else {
      const clickedGoal = event.target.name as Goal;
      const currentSelectedGoals = selectedGoals;

      // Add the item if it is being checked, remove it otherwise
      if (checked) {
        const newGoals = currentSelectedGoals.concat([clickedGoal]);
        setSelectedGoals([...newGoals]);
      } else {
        const index = currentSelectedGoals.indexOf(clickedGoal);
        currentSelectedGoals.splice(index, 1);
        setSelectedGoals([...currentSelectedGoals]);
      }
    }
  };

  // Function to check if the box should be marked checked or not
  const getChecked = (name: Goal) => {
    return selectedGoals.includes(name);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="MA"
              checked={getChecked("MA")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              MA
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="MS"
              checked={getChecked("MS")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              MS
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="PhD"
              checked={getChecked("PhD")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              PhD
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="MSEd"
              checked={getChecked("MSEd")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              MSEd
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="EdD"
              checked={getChecked("EdD")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              EdD
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="EdM"
              checked={getChecked("EdM")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              EdM
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              name="Certificate"
              checked={getChecked("Certificate")}
            />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Certificate
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox onChange={handleChange} name="SA" checked={allSelected} />
          }
          label={
            <Typography variant="body2" color="textSecondary">
              Select All
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}
