import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  TextField,
} from "@mui/material";
import { states } from "../Constants/States";
import React from "react";

// Create a list of just the state abbreviations
const stateAbbreviations = states.map((state) => state.abbreviation);

// Helper function to get the state name from its abbreviation
function getStateName(abbreviation: string) {
  if (abbreviation === "SA") return "Select All";
  return states.find((item) => item.abbreviation === abbreviation)?.name;
}

// Props for the State Filter functional component
interface StateFilterProps {
  selectedStates: string[];
  setSelectedStates: React.Dispatch<React.SetStateAction<string[]>>;
}

// Add SA (for select all) to the list of abbreviations for states
const options = ["SA"].concat(stateAbbreviations);

// Functional component for the state filter autocomplete box. The state variable
// of selected states lives in the parent DegreeProgramsTable component, but values
// are updated in this component
export default function StateFilter(props: StateFilterProps) {
  const { selectedStates, setSelectedStates } = props;

  // Boolean to indicate whether all states in the list are clicked or not
  const allSelected = states.length === selectedStates.length;

  // Update the state value
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason
  ) => {
    if (
      reason === ("selectOption" as AutocompleteChangeReason) ||
      reason === ("removeOption" as AutocompleteChangeReason)
    ) {
      // Handle select all click
      if (value.find((option) => option === "SA")) {
        if (allSelected) setSelectedStates([]);
        else setSelectedStates(stateAbbreviations);
      } else {
        setSelectedStates(value);
      }
    } else if (reason === "clear") {
      setSelectedStates([]);
    }
  };

  // Special logic for rendering the options as list items when the autocomplete box
  // is expanded
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: string,
    state: AutocompleteRenderOptionState
  ) => {
    if (option === "SA") {
      return (
        <li {...props}>
          <Checkbox
            color="primary"
            style={{ marginRight: 8 }}
            checked={allSelected}
          />
          {getStateName(option)}
        </li>
      );
    }
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          style={{ marginRight: 8 }}
          checked={state.selected}
        />
        {getStateName(option)}
      </li>
    );
  };

  return (
    <Box>
      <Autocomplete
        multiple
        size="small"
        limitTags={5}
        options={options}
        value={selectedStates}
        disableCloseOnSelect
        onChange={handleChange}
        getOptionLabel={(option: string) => `${getStateName(option)}`}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
}
