import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";

// Functional component that renders the suggestion form from airtable
function Suggest() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        ml: matches ? 0 : 40,
        mr: matches ? 0 : 40,
        mt: matches ? 0 : 10,
      }}
    >
      <iframe
        title="airtable suggestion form"
        src="https://airtable.com/embed/shrI7ttClJJQ9jv60?backgroundColor=grayLight"
        width="100%"
        height="650"
        style={{
          background: "transparent",
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      />
    </Box>
  );
}

export default Suggest;
