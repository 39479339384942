import {
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import {
  datasets,
  datasetCollections,
  Dataset,
  UseCase,
  DatasetCollection,
} from "../Constants/Datasets";
import { createCommaSeparatedList } from "../Constants/functions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";

// Helper function to create List Item entries that act as links from an array of items
function createCommaSeparatedLinkList(list: UseCase[]) {
  const listLength = list.length;
  return list.map((item, index) => {
    if (listLength > 1 && index < listLength - 1) return item + ", ";
    return (
      <Link key={item.title} to={item.link}>
        {item.title}
      </Link>
    );
  });
}

// Functional component that contains the material and logic for the Build tab of the site
function Build() {
  // State variables to indicate whether the datasets and dataset collections lists are
  // collapsed or expanded
  const [datasetsExpanded, setDatasetsExpanded] = useState<boolean>(true);
  const [datasetCollectionsExpanded, setDatasetCollectionsExpanded] =
    useState<boolean>(true);

  // MUI custom theming hooks
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  // Functions to handle the act of expanding / collapsing the relevant lists
  const handleDatasetsClick = (event: React.MouseEvent<HTMLElement>) => {
    setDatasetsExpanded(!datasetsExpanded);
  };

  const handleDatasetCollectionsClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setDatasetCollectionsExpanded(!datasetCollectionsExpanded);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <Paper
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.dark,
            m: 5,
            p: 3,
            textAlign: "center",
          }}
        >
          <Typography variant={matches ? "h6" : matcheslg ? "h5" : "h3"}>
            <i>Build:</i> Learning Engineering Technical Resources
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 5,
              width: "100%",
              textAlign: "center",
            }}
          >
            These publicly available datasets and collections may help you get
            started with building your own learning engineering solutions. Do
            you think that a resource is missing?{" "}
            <Link to="/suggest" style={{ color: theme.palette.common.black }}>
              Suggest it to us.
            </Link>
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper
          variant="outlined"
          sx={{
            bgcolor: theme.palette.secondary.light,
            height: "fit-content",
            overflow: "hidden",
            m: 5,
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              bgcolor: theme.palette.primary.main,
              borderRadius: 0,
              color: theme.palette.primary.contrastText,
              border: "0px",
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDatasetsClick}
          >
            <Typography variant={matches ? "h6" : "h4"} sx={{ ml: 2 }}>
              Datasets
            </Typography>
            {datasetsExpanded ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </Paper>

          {datasetsExpanded &&
            datasets.map((dataset: Dataset) => {
              return (
                <Paper
                  variant="outlined"
                  sx={{
                    m: 2,
                    p: 2,
                    bgcolor: theme.palette.secondary.light,
                    textAlign: "initial",
                  }}
                  key={dataset.title}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        <Link
                          to={dataset.link}
                          style={{ color: theme.palette.common.black }}
                        >
                          {dataset.title}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>
                          <i>Organization(s):</i>
                        </b>
                      </Typography>
                      <Typography>{dataset.organization}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        <b>
                          <i>Description:</i>
                        </b>
                      </Typography>
                      <Typography>{dataset.description}</Typography>
                    </Grid>
                    {dataset.location && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Location:</i>
                          </b>
                        </Typography>
                        <Typography> {dataset.location}</Typography>
                      </Grid>
                    )}
                    {dataset.scale && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Scale of Study:</i>
                          </b>{" "}
                        </Typography>
                        <Typography>
                          {createCommaSeparatedList(dataset.scale)}
                        </Typography>
                      </Grid>
                    )}
                    {dataset.collectionYears && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Year(s) Collected:</i>
                          </b>
                        </Typography>
                        <Typography>{dataset.collectionYears}</Typography>
                      </Grid>
                    )}
                    {dataset.lastUpdated && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Last Updated:</i>
                          </b>{" "}
                        </Typography>
                        <Typography>{dataset.lastUpdated}</Typography>
                      </Grid>
                    )}
                    {dataset.ageGroup && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Age Group of Participants:</i>
                          </b>{" "}
                        </Typography>
                        <Typography>{dataset.ageGroup}</Typography>
                      </Grid>
                    )}

                    {dataset.download && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Download Available?:</i>
                          </b>
                        </Typography>
                        <Typography>
                          {dataset.download ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    )}
                    {dataset.API && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>API Available?:</i>
                          </b>{" "}
                        </Typography>
                        <Typography>{dataset.API ? "Yes" : "No"}</Typography>
                      </Grid>
                    )}
                    {dataset.useCases && (
                      <Grid item xs={12} md={6}>
                        <Typography>
                          <b>
                            <i>Example Use Cases:</i>
                          </b>{" "}
                        </Typography>
                        <Typography>
                          {createCommaSeparatedLinkList(dataset.useCases)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              );
            })}
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            bgcolor: theme.palette.secondary.light,
            height: "fit-content",
            overflow: "hidden",
            m: 5,
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              bgcolor: theme.palette.primary.main,
              borderRadius: 0,
              color: theme.palette.primary.contrastText,
              border: "0px",
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDatasetCollectionsClick}
          >
            <Typography variant={matches ? "h6" : "h4"} sx={{ ml: 2 }}>
              Dataset Collections
            </Typography>
            {datasetCollectionsExpanded ? (
              <ExpandLessIcon fontSize="large" />
            ) : (
              <ExpandMoreIcon fontSize="large" />
            )}
          </Paper>
          {datasetCollectionsExpanded && (
            <List sx={{ listStyleType: "disc" }}>
              {datasetCollections.map((collection: DatasetCollection) => {
                return (
                  <ListItem
                    sx={{ display: "list-item" }}
                    key={collection.title}
                  >
                    <Box
                      sx={{
                        textAlign: "initial",
                      }}
                      key={collection.title}
                    >
                      <Typography variant="h6">
                        <Link
                          style={{ color: theme.palette.common.black }}
                          to={collection.link}
                        >
                          {collection.title}
                        </Link>
                      </Typography>
                      <Typography sx={{ mt: 1 }}>
                        {collection.description}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Build;
