import { Box } from "@mui/system";
import { getComparator, stableSort } from "./DegreeProgramsTable";
import {
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DegreePrograms } from "../Constants/DegreePrograms";
import { DegreeTableData, createDegreeTableData } from "../Constants/Tables";
import { Link } from "react-router-dom";
import child_laptop_image from "../Constants/images/student-laptop.jpg";

// Map the degree programs into rows for display in the mini table on the Learn landing page
const rows: DegreeTableData[] = DegreePrograms.map((program) => {
  let deadline;
  if (program.deadline) deadline = program.deadline;
  else if (program.rolling) deadline = new Date();
  else deadline = null;
  // Use the program's deadline if specified. Use today's date if unspecified and rolling
  // (for sorting purposes). Use arbitrarily far away date otherwise (so that program will
  // be sorted last according to deadline)
  return createDegreeTableData(
    program.id,
    program.university.name,
    program.programName,
    program.university.city,
    program.university.state,
    program.duration, // set arbitrarily high if unknown
    deadline,
    program.goal,
    program.rolling,
    program.virtual
  );
});

// Today's date
const today = new Date();

// Sort the degree programs according to ascending application deadline
const programsSortedByDeadline = stableSort(
  rows,
  getComparator("asc", "deadline")
).filter((row) => row.deadline && row.deadline > today);

// Splice the list of programs to include only the three programs with the
// nearest application deadline
const nearestApplicationDeadlinePrograms = programsSortedByDeadline.splice(
  0,
  3
);

// Functional component for the Learn landing page, which includes an in depth
// intro to Learning Engineering, a mini degree programs table, and further learning
// resources
function Learn() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={matches ? 2 : 5}>
      <Grid item md={7} xs={12}>
        <Paper
          variant="outlined"
          sx={{ p: 3, m: 5, borderColor: theme.palette.primary.dark }}
        >
          <Typography variant={matches ? "h4" : "h2"}>
            <i>Learn:</i> Introduction to Learning Engineering
          </Typography>
          <Typography variant="body1" sx={{ mt: 3 }}>
            While the field of learning engineering today utilizes modern
            technologies and methodologies, the phrase “learning engineering”
            dates to 1967 when it was coined by cognitive psychologist and
            economist Herbert Simon. Simon believed that education and
            curriculum development should be driven by mathematical principles
            in the same way that physics and engineering were.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            This vision is possible today. Large scale educational technology
            platforms are now generating massive amounts of data.
            Simultaneously, new computer science tools, like improved
            computation, AI, and natural language processing, enable quicker and
            more novel data-driven insights. In order to leverage this, learning
            engineers combine these nascent computer and data advances with
            theories of learning science to create evidence-based learning
            systems that may be continuously and rapidly iterated upon to
            improve student outcomes.
          </Typography>
          <Typography variant="h6" sx={{ mt: 3 }}>
            Learning engineering is computer science + data science + learning
            science, applied in real education settings.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            <b>
              What Learning Engineering is <i>not</i>
            </b>
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Ed-tech and learning engineering are not necessarily synonymous.
            While learning engineering solutions often employ technology as part
            of their approach, their goal to improve student outcomes is not
            bounded by technology exclusively. Sometimes software may serve the
            purpose of iteratively improving the in-person instruction being
            provided by an educator.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Learning engineering is not learning science alone. Learning
            engineering seeks to improve the process and efficiency of learning
            science research and to apply its findings through platform
            instrumentation and other learning innovations.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            <b>Learning Engineering in Practice</b>
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            The process of learning engineering begins with learning science to
            provide context and understanding to the learning challenge being
            addressed. With that understanding in place, human centered and
            engineering design principles are used to create and iterate on a
            proposed solution. Next, that solution is engineered, with data
            collection integrated to allow for analysis of the solution’s
            effectiveness. Data driven decision making then determines which
            problems to solve next and/or which designs and technical
            implementations to further iterate on.
          </Typography>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box sx={{ p: 1 }}>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  This entire process requires perspectives in:
                </Typography>
                <List sx={{ listStyleType: "disc", pl: 4 }}>
                  <ListItem key={1} sx={{ display: "list-item" }}>
                    <Typography variant="body1">Learning Science</Typography>
                  </ListItem>
                  <ListItem key={2} sx={{ display: "list-item" }}>
                    <Typography variant="body1">Data Science</Typography>
                  </ListItem>
                  <ListItem key={3} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Software Engineering
                    </Typography>
                  </ListItem>
                  <ListItem key={4} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Instructional Design
                    </Typography>
                  </ListItem>
                  <ListItem key={5} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Learning Environment Engineering
                    </Typography>
                  </ListItem>
                  <ListItem key={6} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Education and Training Professional Practices
                    </Typography>
                  </ListItem>
                  <ListItem key={7} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Assessment, Measurement, Evaluation
                    </Typography>
                  </ListItem>
                  <ListItem key={8} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      Subject Matter Expertise
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box sx={{ p: 1 }}>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Some common learning engineering approaches in the real world
                  include:
                </Typography>
                <List sx={{ listStyleType: "disc", pl: 4 }}>
                  <ListItem key={1} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      <b>A/B Testing</b> to determine which of two versions of a
                      given program is most effective.
                    </Typography>
                  </ListItem>
                  <ListItem key={2} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      <b>Educational Data Mining</b> to draw insights on
                      learning outcomes from student use of educational
                      software.
                    </Typography>
                  </ListItem>
                  <ListItem key={3} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      <b>Platform Instrumentation</b> of ed-tech solutions for
                      teachers and students.
                    </Typography>
                  </ListItem>
                  <ListItem key={4} sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      <b>Dataset Generation</b> to enable learning science
                      research.
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ p: 3, m: 5, borderColor: theme.palette.primary.dark }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6">
              More About Learning Engineering
            </Typography>
            <Button
              component={Link}
              to={"https://en.wikipedia.org/wiki/Learning_engineering"}
            >
              Learning Engineering Wikipedia
            </Button>
            <Button
              component={Link}
              to={
                "https://sagroups.ieee.org/icicle/learning-engineering-process/"
              }
            >
              IEEE: What is Learning Engineering?
            </Button>
            <Button
              component={Link}
              to={
                "https://www.the-learning-agency.com/learning-engineering-resources/"
              }
            >
              The Learning Agency: Learning Engineering Hub
            </Button>
            <Button
              component={Link}
              to={
                "https://www.the-learning-agency.com/wp-content/uploads/2021/08/Learning-Engineering_UPenn.pdf"
              }
            >
              High Leverage Opportunities for Learning Engineering (Ryan Baker
              and Ulrich Boser)
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={5} xs={12}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Paper
            elevation={20}
            sx={{ m: 5, borderRadius: 1, overflow: "hidden", width: "75%" }}
          >
            <img
              src={child_laptop_image}
              width="100%"
              style={{ display: "block" }}
              alt="child learning on a device"
            />
          </Paper>
        </Box>
        <Paper
          variant="outlined"
          sx={{ m: 5, borderColor: theme.palette.primary.dark }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" color="inherit">
              <Link
                to={"/learn/degree-programs"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Explore Degree Programs in Learning Engineering
              </Link>
            </Typography>
            <Typography variant="subtitle1">
              <Box sx={{ mt: 2 }}>
                <TableContainer>
                  <b>
                    <i>Nearest Application Deadlines:</i>
                  </b>
                  <Table component="div">
                    <TableBody component="div">
                      {nearestApplicationDeadlinePrograms.map((program) => {
                        return (
                          <TableRow
                            hover
                            sx={{
                              textDecoration: "none",
                              // Figure out how to get theme colors
                              borderTop: "1px solid rgba(224, 224, 224, 1)",
                            }}
                            component={Link}
                            to={`/learn/degree-programs/${program.id}`}
                            key={program.id}
                          >
                            <TableCell component="div">
                              {program.university}, {program.programName}
                            </TableCell>
                            <TableCell component="div">
                              Apply By{" "}
                              {program.deadline &&
                                program.deadline.toLocaleDateString("en-us", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Button component={Link} to={"/learn/degree-programs"}>
                View the Full List
              </Button>
            </Box>
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{ p: 3, m: 5, borderColor: theme.palette.primary.dark }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6">Other Learning Resources</Typography>
            <Button
              component={Link}
              to={"https://www.edx.org/course/big-data-and-education"}
            >
              Big Data and Education Massive Open Online Course (MOOC)
            </Button>
            <Button
              component={Link}
              to={
                "https://learninganalytics.upenn.edu/MOOT/bigdataeducation.html"
              }
            >
              Big Data and Education Massive Open Online Textbook (MOOT)
            </Button>
            <Button component={Link} to={"https://datascienceineducation.com/"}>
              Data Science in Education Using R
            </Button>
            <Button
              component={Link}
              to={
                "https://www.amazon.com/Learning-Engineering-Toolkit-Jim-Goodell/dp/103223282X/ref=sr_1_3?crid=E7N9Y3MK1SI7&keywords=learning+engineering&qid=1674688473&s=books&sprefix=learning+engineerin%2Cstripbooks%2C141&sr=1-3"
              }
            >
              Learning Engineering Toolkit
            </Button>
            <Button
              component={Link}
              to={
                "https://www.amazon.com/Learning-Engineering-Online-Education-Design-Based/dp/081539442X/ref=sr_1_4?crid=E7N9Y3MK1SI7&keywords=learning+engineering&qid=1674688510&s=books&sprefix=learning+engineerin%2Cstripbooks%2C141&sr=1-4"
              }
            >
              Learning Engineering for Online Education
            </Button>
            <Button
              component={Link}
              to={
                "https://learnlab.org/online-education-building-expertise-in-learning-engineering/"
              }
            >
              LearnLab’s Learning Engineering Certificates
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Learn;
